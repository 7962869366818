import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function camelToTitleCase(s: string): string {
	const result = s.replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
}

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}
