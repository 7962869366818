import type {
	ApiRequestResponse,
	ClientUserModel,
} from "@coffitivity/shared-ts-types";
import * as Sentry from "@sentry/react";

export const userService = {
	delete: async (
		_id: string,
		_accessToken: string,
	): Promise<ApiRequestResponse<object>> => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/user/${_id}`,
				{
					method: "DELETE",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${_accessToken}`,
					},
				},
			);

			const json: { data: object; message: string } = await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (error) {
			console.error(`Error: ${error}`);
			Sentry.captureException(error);
			return [null, error as Error];
		}
	},
	getMe: async (
		_accessToken: string,
	): Promise<ApiRequestResponse<ClientUserModel>> => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/user/me`,
				{
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${_accessToken}`,
					},
				},
			);

			const json: { data: ClientUserModel; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
	updatePassword: async (
		_userId: string,
		_accessToken: string,
		_oldPassword: string,
		_newPassword: string,
	): Promise<ApiRequestResponse<object>> => {
		try {
			const payload = {
				oldPassword: _oldPassword,
				newPassword: _newPassword,
			};

			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/user/${_userId}/password`,
				{
					method: "PUT",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${_accessToken}`,
					},
					body: JSON.stringify(payload),
				},
			);

			const json: { data: object; message: string } = await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (error) {
			console.error(`Error: ${error}`);
			Sentry.captureException(error);
			return [null, error as Error];
		}
	},
	updateFromPayment: async (
		_id: string,
		_accessToken: string,
	): Promise<ApiRequestResponse<ClientUserModel>> => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/user/${_id}/update-from-payment`,
				{
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${_accessToken}`,
					},
				},
			);

			const json: { data: ClientUserModel; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
};

export default userService;
