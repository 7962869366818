import type { TrackGroup } from "./Types";
import creativity from "@/assets/groupImages/creativity.png";
import stimulation from "@/assets/groupImages/stimulation.png";
import focus from "@/assets/groupImages/focus.png";
import energy from "@/assets/groupImages/energy.png";
import relaxation from "@/assets/groupImages/relaxation.png";
import meditation from "@/assets/groupImages/meditation.png";
import mindfulness from "@/assets/groupImages/mindfulness.png";
import productivity from "@/assets/groupImages/productivity.png";
import sleep from "@/assets/groupImages/sleep.png";

export type TrackEffects = TrackGroup[];

const trackEffects: TrackEffects = [
	{
		id: "creativity",
		name: "creativity",
		description:
			"Unleash your imagination with soundscapes that spark inspiration. Whether you're brainstorming, writing, or designing, these tracks help your ideas flow effortlessly.",
		imageUrl: creativity,
	},
	{
		id: "stimulation",
		name: "stimulation",
		description:
			"Give your brain a boost! These energizing sounds increase mental alertness, fight fatigue, and help you dive into deep focus with renewed motivation.",
		imageUrl: stimulation,
	},
	{
		id: "focus",
		name: "focus",
		description:
			"Stay locked in and distraction-free. These sounds provide the ideal background for studying, reading, or working deeply on your next project.",
		imageUrl: focus,
	},
	{
		id: "energy",
		name: "energy",
		description:
			"Power through your day with uplifting, invigorating soundscapes. Whether you need a pick-me-up or a push to get things done, these tracks fuel your momentum.",
		imageUrl: energy,
	},
	{
		id: "relaxation",
		name: "relaxation",
		description:
			"Melt away stress with calming atmospheric sounds designed to soothe the mind and body. The perfect escape after a long day.",
		imageUrl: relaxation,
	},
	{
		id: "meditation",
		name: "meditation",
		description:
			"Center yourself with peaceful audio crafted for reflection and inner balance. Ideal for mindfulness, deep breathing, or simply finding a moment of calm.",
		imageUrl: meditation,
	},
	{
		id: "mindfulness",
		name: "mindfulness",
		description:
			"Be present in the moment with serene soundscapes that encourage awareness and clarity. Perfect for meditation, yoga, or unwinding mindfully.",
		imageUrl: mindfulness,
	},
	{
		id: "productivity",
		name: "productivity",
		description:
			"Work smarter with sound environments designed to enhance efficiency. Whether tackling big projects or daily tasks, these tracks help you stay on track.",
		imageUrl: productivity,
	},
	{
		id: "sleep",
		name: "sleep",
		description:
			"Drift into deep, restorative rest with soothing auditory atmospheres. Gentle, rhythmic sounds help quiet the mind and promote better sleep.",
		imageUrl: sleep,
	},
];

export default trackEffects;
