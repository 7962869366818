import { Skeleton } from "@/components/ui/skeleton";
import { Suspense } from "react";
import { Link, Outlet } from "react-router";

const Fallback = () => (
	<div className="grid min-h-svh lg:grid-cols-2">
		<div className="relative hidden bg-muted lg:block">
			<img
				src="/placeholder.svg"
				alt="Login background"
				className="absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
			/>
		</div>
		<div className="flex flex-col gap-4 p-6 md:p-10">
			<div className="flex justify-center gap-2 md:justify-start">
				<div className="flex items-center gap-2 text-lg font-medium">
					<div className="flex h-12 w-12 items-center justify-center rounded-md bg-primary text-primary-foreground">
						<Skeleton className="h-12 w-12 rounded-medium" />
					</div>
					Coffitivity
				</div>
			</div>
			<div className="flex flex-1 items-center justify-center">
				<div className="w-full max-w-xs space-y-2">
					<Skeleton className="h-4 w-3/4 m-auto" />
					<Skeleton className="h-[150px] w-full" />
				</div>
			</div>
		</div>
	</div>
);

const AuthLayout = () => (
	<Suspense fallback={<Fallback />}>
		<div className="grid min-h-svh lg:grid-cols-2">
			<div className="relative hidden bg-muted lg:block">
				<img
					src="/abstract/abstract_8.png"
					alt="Login background"
					className="absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
				/>
			</div>
			<div className="flex flex-col gap-4 p-6 md:p-10">
				<div className="flex justify-center gap-2">
					<Link
						to="/"
						className="flex items-center gap-2 font-[Lora] text-lg font-medium"
					>
						<img
							className="h10 w-10 rounded-l"
							src="/new-square-icon.svg"
							alt="Logo"
						/>
						coffitivity
					</Link>
				</div>
				<div className="flex flex-1 items-center justify-center">
					<div className="w-full max-w-sm border bg-background rounded-lg p-4 py-6">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	</Suspense>
);

export default AuthLayout;
