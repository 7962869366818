import { Link } from "react-router";
import { Home } from "lucide-react";

export default function NotFound() {
	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
			<div className="max-w-md w-full space-y-8 text-center">
				<div className="space-y-4">
					<h1 className="text-6xl font-extrabold text-primary sm:text-8xl">
						404
					</h1>
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						Page not found
					</h2>
					<p className="text-base text-gray-600 sm:text-lg">
						Sorry, we couldn't find the page you're looking for.
					</p>
				</div>
				<div className="mt-8">
					<Link
						to="/"
						className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
					>
						<Home className="mr-2 -ml-1 h-5 w-5" aria-hidden="true" />
						Go back home
					</Link>
				</div>
			</div>
		</div>
	);
}
