class Timer {
	private duration: number; // Total duration in seconds
	private elapsedTime = 0; // Elapsed time in seconds
	private timerId: number | null = null; // setInterval reference

	public currentTime = 0; // Elapsed time in seconds
	public remainingTime: number; // Remaining time in seconds

	constructor(duration: number) {
		this.duration = duration;
		this.remainingTime = duration;
	}

	public start(): void {
		if (this.timerId !== null) return; // Prevent multiple intervals

		this.timerId = window.setInterval(() => {
			this.elapsedTime += 1; // Increment by 1 second
			this.currentTime = this.elapsedTime;
			this.remainingTime = Math.max(this.duration - this.currentTime, 0);

			if (this.remainingTime <= 0) {
				this.pause(); // Stop when time reaches zero
			}
		}, 1000); // Runs every second
	}

	public pause(): void {
		if (this.timerId !== null) {
			clearInterval(this.timerId);
			this.timerId = null;
		}
	}

	public reset(): void {
		this.pause();
		this.elapsedTime = 0;
		this.currentTime = 0;
		this.remainingTime = this.duration;
	}
}

export type TimerType = InstanceType<typeof Timer>;

export default Timer;
