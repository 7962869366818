import { Home, User, Library } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";
import { useAuth } from "@/providers/auth-provider";

interface NavButtonProps {
	icon: React.ReactNode;
	label: string;
	to: string;
}

function NavButton({ icon, label, to }: NavButtonProps) {
	const navigate = useNavigate();

	return (
		<Button
			variant={"brand"}
			className="flex flex-col items-center justify-center h-full w-full rounded-none gap-0"
			onClick={() => navigate(to)}
		>
			{icon}
			<span className="text-xs mt-1">{label}</span>
		</Button>
	);
}

export default function mobileNavbarBottom() {
	const { isAuthenticated } = useAuth();

	return (
		<nav className="fixed bottom-0 left-0 right-0 border-t border-border">
			<div className="flex justify-around items-center h-16">
				<NavButton icon={<Home className="h-6 w-6" />} label="Home" to="/" />
				{/* <NavButton
					icon={<Play className="h-6 w-6" />}
					label="Start"
					to="/bar"
				/> */}
				<NavButton
					icon={<Library className="h-6 w-6" />}
					label="Library"
					to="/library"
				/>
				<NavButton
					icon={<User className="h-6 w-6" />}
					label={isAuthenticated ? "Account" : "Log In"}
					to={isAuthenticated ? "/account" : "/auth/log-in"}
				/>
			</div>
		</nav>
	);
}
