import { Link } from "react-router";
import {
	Sheet,
	SheetContent,
	// SheetHeader,
	// SheetTitle,
} from "@/components/ui/sheet";
import ThemeSelector from "@/components/theme-selector";

interface NavDrawerProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

export function MobileNavDrawer({ isOpen, setIsOpen }: NavDrawerProps) {
	const navItems = [
		{ href: "/about", label: "About" },
		{ href: "/pricing", label: "Pricing" },
		{ href: "/contact", label: "Contact" },
		{ href: "https://blog.coffitivity.com", label: "Blog" },
	];

	return (
		<Sheet open={isOpen} onOpenChange={setIsOpen}>
			<SheetContent
				side="right"
				className="w-[300px] sm:w-[400px] bg-background border-none"
			>
				<ThemeSelector />
				{/* <SheetHeader>
					<SheetTitle>Navigation</SheetTitle>
				</SheetHeader> */}
				<nav className="flex flex-col gap-4 mt-12">
					{navItems.map((item) => (
						<Link
							key={item.href}
							to={item.href}
							className="text-3xl font-medium pb-2"
							onClick={() => setIsOpen(false)}
						>
							{item.label}
						</Link>
					))}
				</nav>
			</SheetContent>
		</Sheet>
	);
}
