import { useState } from "react";
import { ChevronLeft, LogOut, Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import { MobileNavDrawer } from "./mobile-nav-drawer";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "@/providers/auth-provider";

const homeNavLinks = (setIsOpenFn: (isOpen: boolean) => void) => (
	<>
		<div className="flex items-center gap-2 text-lg font-medium">
			<img
				className="h10 w-10 rounded-lg"
				src="/new-square-icon.svg"
				alt="Logo"
			/>
			Coffitivity
		</div>
		<Button variant="secondary" size="icon" onClick={() => setIsOpenFn(true)}>
			<Menu className="h-6 w-6" />
			<span className="sr-only">Open menu</span>
		</Button>
	</>
);

const BackButtonNav = () => {
	const navigate = useNavigate();

	return (
		<Button variant="secondary" size="icon" onClick={() => navigate(-1)}>
			<ChevronLeft className="h-6 w-6" />
			<span className="sr-only">Open menu</span>
		</Button>
	);
};

export function MobileNavbarTop() {
	const { logout } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(false);

	const isHomePage = location.pathname === "/";
	const isAccountPage = location.pathname === "/account";

	const handleLogout = () => {
		logout();
		navigate("/");
	};

	return (
		<nav className="top-0 left-0 right-0">
			<div className="mx-auto px-4 sm:px-8 h-16 flex items-center justify-between">
				{isHomePage ? homeNavLinks(setIsOpen) : <BackButtonNav />}
				{isAccountPage && (
					<Button variant="secondary" onClick={handleLogout}>
						<span>Logout</span>
						<LogOut className="h-6 w-6" />
					</Button>
				)}
			</div>
			<MobileNavDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
		</nav>
	);
}
