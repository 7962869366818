import type { TrackGroup } from "./Types";
import cafe from "@/assets/groupImages/cafe.png";
import soundscape from "@/assets/groupImages/soundscape.png";
import brainWave from "@/assets/groupImages/brainWave.png";
import soundColor from "@/assets/groupImages/soundColor.png";

export type TrackCategory = TrackGroup[];

const trackCollections: TrackCategory = [
	{
		id: "cafe",
		name: "café",
		description:
			"Bring the buzz of your favorite café to your workspace. From quiet neighborhood coffee shops to bustling urban espresso bars, these soundscapes recreate the perfect café ambiance to boost creativity and focus.",
		imageUrl: cafe,
	},
	{
		id: "soundscape",
		name: "soundscape",
		description:
			"Immerse yourself in the world's most soothing and inspiring environments. From tranquil forests to lively city streets, these ambient recordings transport you to places that enhance relaxation, mindfulness, and deep focus.",
		imageUrl: soundscape,
	},
	{
		id: "brainWave",
		name: "brainWave",
		description:
			"Enhance your cognitive flow with brainwave-boosting audio. Designed to support focus, creativity, and relaxation, these scientifically informed tracks use binaural beats and rhythmic patterns to optimize your mental state.",
		imageUrl: brainWave,
	},
	{
		id: "soundColor",
		name: "soundColor",
		description:
			"Experience the science of sound with white noise, pink noise, and other sonic textures. Perfect for relaxation, deep concentration, and blocking out distractions, these tonal atmospheres help you create the ideal audio environment.",
		imageUrl: soundColor,
	},
];

export default trackCollections;
