import "@/components/error-monitoring";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router";
import { ThemeProvider } from "./providers/theme-provider";
import AuthProvider from "./providers/auth-provider";
import "./index.css";
import AudioProvider from "./providers/audio-provider";
import App from "./app";

const root = document.getElementById("root");

if (root === null) {
	throw new Error("Root element not found");
}

createRoot(root).render(
	<StrictMode>
		<BrowserRouter>
			<ThemeProvider>
				<AuthProvider>
					<AudioProvider>
						<App />
					</AudioProvider>
				</AuthProvider>
			</ThemeProvider>
		</BrowserRouter>
	</StrictMode>,
);
