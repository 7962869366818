import { cn } from "@/lib/utils";
import { useAuth } from "@/providers/auth-provider";
import { Link, useLocation } from "react-router";
import {
	Alert,
	AlertContainer,
	AlertDescription,
	AlertTitle,
} from "./ui/alert";
import { ClockAlert, MoveRight } from "lucide-react";
import { userAnalytics } from "@/lib/userAnalytics";

const GetPremiumAlert = ({ className }: { className?: string }) => {
	const location = useLocation();
	const { user } = useAuth();
	const style = user?.isSubscribed ? "hidden" : "w-full mb-4 md:mb-10";

	const hiddenPages = [
		"/pricing",
		"/checkout",
		"/terms",
		"/contact",
		"/account",
	];
	const hiddenPageStyle = hiddenPages.includes(location.pathname)
		? "hidden"
		: "";

	return (
		<section className={cn(style, hiddenPageStyle, className)}>
			<Link
				to="/pricing"
				onClick={() => userAnalytics.trackPremiumAlertClicked()}
			>
				<Alert variant="primary">
					<AlertContainer>
						<ClockAlert size={24} className="block ml-2 mr-4 shrink-0" />
						<div>
							<AlertTitle>Heads up!</AlertTitle>
							<AlertDescription>
								You're missing out on our full experience. Get{" "}
								<span className="font-bold">40% off</span> with our annual plan.{" "}
								<span className="underline">Cancel anytime</span>.
							</AlertDescription>
						</div>
					</AlertContainer>
					<MoveRight size={24} className="shrink-0 mx-2" />
				</Alert>
			</Link>
		</section>
	);
};

export default GetPremiumAlert;
