import {
	Coffee,
	Handshake,
	Map as MapIcon,
	MessageCircleWarning,
	MessageSquareHeart,
	PartyPopper,
	Sparkles,
	Star,
	Grid2X2,
} from "lucide-react";

import { NavMain } from "@/components/nav-main";
import { NavSecondary } from "@/components/nav-secondary";
import { NavUser } from "@/components/nav-user";
import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from "@/components/ui/sidebar";
import { Link } from "react-router";
import trackCollections from "@/data/trackCollections";
import trackEffects from "@/data/trackEffects";
import { camelToTitleCase } from "@/lib/utils";
import ThemeSelector from "./theme-selector";

const data = {
	navMain: [
		{
			title: "Free",
			url: "/library/free",
			icon: PartyPopper,
		},
		{
			title: "Explore",
			url: "/library",
			icon: MapIcon,
		},
		{
			title: "By Collection",
			url: "#", // this will open the subnav rather than link to a new page
			icon: Grid2X2,
			isActive: true,
			items: trackCollections.map((trackCollection) => ({
				title: camelToTitleCase(trackCollection.name),
				url: `/library/collection/${trackCollection.id}`,
			})),
		},
		{
			title: "By Effect",
			url: "#", // this will open the subnav rather than link to a new page
			icon: Sparkles,
			isActive: false,
			items: trackEffects.map((trackEffect) => ({
				title: camelToTitleCase(trackEffect.name),
				url: `/library/effect/${trackEffect.id}`,
			})),
		},
	],
	navSecondary: [
		{
			title: "About Us",
			url: "/about",
			icon: Coffee,
		},
		// {
		// 	title: "FAQs",
		// 	url: "#",
		// 	icon: CircleHelp,
		// },
		{
			title: "Contact",
			url: "/contact",
			icon: MessageCircleWarning,
		},
		{
			title: "Premium",
			url: "/pricing",
			icon: Star,
		},
		{
			title: "Blog",
			url: "https://blog.coffitivity.com",
			icon: MessageSquareHeart,
		},
		{
			title: "Terms",
			url: "/terms",
			icon: Handshake,
		},
	],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
	return (
		<Sidebar variant="inset" {...props}>
			<SidebarHeader>
				<SidebarMenu>
					<SidebarMenuItem>
						<SidebarMenuButton size="lg" asChild>
							<Link to="/">
								<div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
									<img
										className="h10 w-10 rounded-lg"
										src="/new-square-icon.svg"
										alt="Logo"
									/>
								</div>
								<div className="grid flex-1 text-left text-lg leading-tight">
									<span className="font-[Lora] truncate font-semibold">
										coffitivity
									</span>
								</div>
							</Link>
						</SidebarMenuButton>
					</SidebarMenuItem>
				</SidebarMenu>
			</SidebarHeader>
			<SidebarContent>
				<NavMain items={data.navMain} />
				<NavSecondary items={data.navSecondary} className="mt-auto" />
			</SidebarContent>
			<SidebarFooter>
				<NavUser />
				<ThemeSelector />
			</SidebarFooter>
		</Sidebar>
	);
}
