import { Laptop, Moon, Sun } from "lucide-react";
import { type Theme, useTheme } from "@/providers/theme-provider";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Label } from "./ui/label";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "./ui/tooltip";

const ThemeSelector = () => {
	const { theme, setTheme } = useTheme();

	return (
		<div className="flex h-11 w-full items-center rounded-md p-1">
			<RadioGroup
				defaultValue={theme}
				className="flex h-full justify-between"
				onValueChange={(value) => {
					setTheme(value as Theme);
				}}
			>
				<div className='h-full rounded-md transition-all has-[button[data-state="checked"]]:bg-sidebar-accent hover:bg-sidebar-accent'>
					<RadioGroupItem value="system" id="system" className="peer sr-only" />
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Label
									htmlFor="system"
									className="flex p-2 h-full cursor-pointer items-center justify-center gap-1 font-semibold text-muted-foreground peer-data-[state=checked]:text-primary"
								>
									<Laptop size={24} className="shrink-0 " />
								</Label>
							</TooltipTrigger>
							<TooltipContent>
								<p>System Theme</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<div className='h-full rounded-md transition-all has-[button[data-state="checked"]]:bg-sidebar-accent hover:bg-sidebar-accent'>
					<RadioGroupItem value="dark" id="dark" className="peer sr-only" />
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Label
									htmlFor="dark"
									className="flex p-2 h-full cursor-pointer items-center justify-center font-semibold text-muted-foreground peer-data-[state=checked]:text-primary"
								>
									<Moon size={24} className="shrink-0 " />
								</Label>
							</TooltipTrigger>
							<TooltipContent>
								<p>Dark Theme</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<div className='h-full rounded-md transition-all has-[button[data-state="checked"]]:bg-sidebar-accent hover:bg-sidebar-accent'>
					<RadioGroupItem value="light" id="light" className="peer sr-only" />
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Label
									htmlFor="light"
									className="flex p-2 h-full cursor-pointer items-center justify-center gap-1 font-semibold text-muted-foreground peer-data-[state=checked]:text-primary"
								>
									<Sun size={24} className="shrink-0 " />
								</Label>
							</TooltipTrigger>
							<TooltipContent>
								<p>Light Theme</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</RadioGroup>
		</div>
	);
};

export default ThemeSelector;
