import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import type { PlayerAlerts } from "@/providers/audio-provider";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { userAnalytics } from "@/lib/userAnalytics";
import getPremiumImage from "@/assets/get-premium-1.png";
import { Check } from "lucide-react";

interface TrackModalProps {
	alert: PlayerAlerts | null;
	isOpen: boolean;
	trackId?: string;
	close: () => void;
}

export function InterruptionModal({ isOpen, close, trackId }: TrackModalProps) {
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			userAnalytics.tracKModalOpened("interruption_modal", trackId);
		}
	}, [isOpen, trackId]);

	const handleClick = () => {
		userAnalytics.trackModalConverted("interruption_modal", trackId);
		close();
		navigate("/pricing");
	};

	return (
		<Dialog open={isOpen} onOpenChange={close}>
			<DialogContent className="sm:max-w-[500px] p-0 overflow-hidden">
				<div className="flex flex-col">
					<div className="hidden md:block relative w-full aspect-square">
						<img
							src={getPremiumImage}
							alt="Premium upgrade"
							className="object-cover"
						/>
					</div>
					<div className="p-6">
						<DialogHeader className="mb-4">
							<DialogTitle className="text-2xl">Upgrade to Premium</DialogTitle>
							<DialogDescription>
								Take your experience to the next level with our premium
								offering.
							</DialogDescription>
						</DialogHeader>

						<div className="space-y-3 mb-6">
							<div className="flex items-start gap-2">
								<Check className="size-6 bg-accent text-primary rounded-full p-1 flex-shrink-0" />
								<p className="text-sm">No playback interruptions</p>
							</div>
							<div className="flex items-start gap-2">
								<Check className="size-6 bg-accent text-primary rounded-full p-1 flex-shrink-0" />
								<p className="text-sm">3 Day no-risk free trial period</p>
							</div>
							<div className="flex items-start gap-2">
								<Check className="size-6 bg-accent text-primary rounded-full p-1 flex-shrink-0" />
								<p className="text-sm">Access to our full library</p>
							</div>
							<div className="flex items-start gap-2">
								<Check className="size-6 bg-accent text-primary rounded-full p-1 flex-shrink-0" />
								<p className="text-sm">Easy any time cancellation</p>
							</div>
						</div>

						<div className="flex flex-col gap-3 mt-6">
							<Button className="w-full" onClick={handleClick}>
								Upgrade Now
							</Button>
							<Button variant="ghost" className="w-full" onClick={close}>
								Maybe Later
							</Button>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
