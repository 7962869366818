import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useAuth } from "./auth-provider";
import { WhiteNoiseGenerator } from "@/lib/WhiteNoiseGenerator";
import Timer, { type TimerType } from "@/lib/Timer";
import type { Track } from "@/data/Types";
import { userAnalytics } from "@/lib/userAnalytics";

export type PlayerAlerts =
	| "PREMIUM_REQUIRED"
	| "UNKNOWN_ERROR"
	| "TIME_LIMIT"
	| null;

interface AudioContextProps {
	alert: PlayerAlerts;
	alertedTrack?: string;
	audioRef: React.RefObject<HTMLAudioElement>;
	clearAlert: () => void;
	currentTrack: Track | null;
	handleMuteEvent: () => void;
	isPlaying: boolean;
	isUserEngaged: boolean;
	pause: () => void;
	play: () => void;
	resetTimer: () => void;
	selectTrack(track: Track): void;
	setVolume: (volume: number) => void;
	setWhiteNoiseFilters: (filters: Record<string, number>) => void;
	volume: number;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

export const useAudio = () => {
	const context = useContext(AudioContext);
	if (!context) {
		throw new Error("useAudio must be used within an AudioProvider");
	}
	return context;
};

const AudioProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const audioRef = useRef<HTMLAudioElement>(null);
	const [alert, setAlert] = useState<PlayerAlerts>(null);
	const [alertedTrack, setAlertedTrack] = useState<string | undefined>(
		undefined,
	);
	const [volume, setVolumeState] = useState(0.75);
	const [previousVolume, setPreviousVolume] = useState(0.9);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTrack, setCurrentTrack] = useState<Track | null>(null);
	const { isAuthenticated, isSubscribed } = useAuth();
	const [isUserEngaged, setIsUserEngaged] = useState(false);

	// White Noise Generator Ref
	const noiseGeneratorRef = useRef<WhiteNoiseGenerator | null>(null);
	const unsubscribedTimerRef = useRef<TimerType | null>(new Timer(10 * 60)); // 10 minute timer

	const handleKeyboardEvents = useCallback(
		(event: KeyboardEvent) => {
			const target = event.target as HTMLElement;
			const isTypingElement = ["INPUT", "TEXTAREA", "SELECT"].includes(
				target.tagName,
			);
			if (isTypingElement) return;
			if (event.key === " " || event.code === "Space") {
				event.preventDefault();
				if (isPlaying) {
					pause();
				} else {
					play();
				}
			}
		},
		[isPlaying],
	);

	const clearAlert = () => {
		setAlert(null);
		setAlertedTrack(undefined);
	};

	/** Pause both audio and white noise */
	const pause = () => {
		if (audioRef.current) {
			audioRef.current.pause();
		}

		if (noiseGeneratorRef.current) {
			noiseGeneratorRef.current.stop();
		}

		setIsPlaying(false);

		if (unsubscribedTimerRef.current) {
			unsubscribedTimerRef.current.pause();
		}

		// track playback paused
		if (currentTrack) {
			userAnalytics.trackPlaybackPaused(currentTrack?.id);
		}
	};

	/** Play the currently selected track (either audio file or white noise) */
	const play = () => {
		if (currentTrack?.whiteNoiseConfig) {
			// If a white noise track is playing
			if (!noiseGeneratorRef.current) {
				const generator = new WhiteNoiseGenerator(
					currentTrack.whiteNoiseConfig,
					volume,
				);
				noiseGeneratorRef.current = generator;
				generator.start();
			} else {
				noiseGeneratorRef.current.setVolume(volume);
				noiseGeneratorRef.current.start();
			}
		} else if (audioRef.current) {
			// If a file-based track is playing
			audioRef.current.play();
		}

		setIsPlaying(true);

		if (!isSubscribed) {
			if (unsubscribedTimerRef.current) {
				unsubscribedTimerRef.current.start();
			}
		}

		// track playback started
		if (currentTrack) {
			userAnalytics.trackPlaybackStarted(currentTrack.id);
		}
	};

	/**
	 * Select a new track.
	 * If the track has a `trackUrl`, play an audio file.
	 * If the track has `whiteNoiseConfig`, generate and play white noise.
	 */
	const selectTrack = (track: Track | null) => {
		if (!isUserEngaged) setIsUserEngaged(true);

		if (track) {
			if (track.isPremium && !(isAuthenticated && isSubscribed)) {
				setAlertedTrack(track.id);
				setAlert("PREMIUM_REQUIRED");
				return;
			}

			setCurrentTrack(track);

			// reset the timer when a new track is selected
			if (unsubscribedTimerRef.current) {
				unsubscribedTimerRef.current.reset();
			}

			if (track.type === "file") {
				// If this is a file-based track, stop white noise and use audioRef
				if (noiseGeneratorRef.current) {
					noiseGeneratorRef.current.stop();
					noiseGeneratorRef.current = null;
				}

				audioRef.current?.load();
			} else if (track.whiteNoiseConfig) {
				// if there is a regular track playing, stop it
				if (audioRef.current) {
					audioRef.current.pause();
				}

				// If this is a white noise track, stop the current audio and start white noise
				if (noiseGeneratorRef.current) {
					noiseGeneratorRef.current.stop();
				}

				const generator = new WhiteNoiseGenerator(
					track.whiteNoiseConfig,
					volume,
				);

				noiseGeneratorRef.current = generator;
				generator.start();
			}

			setIsPlaying(true);

			if (!isSubscribed) {
				if (unsubscribedTimerRef.current) {
					unsubscribedTimerRef.current.start();
				}
			}

			// track playback started
			userAnalytics.trackPlaybackStarted(track.id);
		} else {
			setCurrentTrack(null);
			if (noiseGeneratorRef.current) {
				noiseGeneratorRef.current.stop();
				noiseGeneratorRef.current = null;
			}
		}
	};

	/** Update white noise filter settings dynamically */
	const setWhiteNoiseFilters = (filters: Record<string, number>) => {
		if (noiseGeneratorRef.current) {
			noiseGeneratorRef.current.setFilterGains(filters);
		}
	};

	/** Set audio volume */
	const setVolume = (volume: number) => {
		const normalizedVolume = volume > 1 ? volume / 100 : volume;
		setVolumeState(normalizedVolume);

		if (audioRef.current) {
			audioRef.current.volume = normalizedVolume;
		}

		if (noiseGeneratorRef.current) {
			noiseGeneratorRef.current.setVolume(normalizedVolume);
		}
	};

	/** Mute/unmute volume */
	const handleMuteEvent = () => {
		if (volume > 0) {
			setPreviousVolume(volume);
			setVolume(0);
		} else {
			setVolume(previousVolume);
		}
	};

	const handleTimerReset = () => {
		if (unsubscribedTimerRef.current) {
			unsubscribedTimerRef.current.reset();

			// remove this to make the timer continue after alert
			unsubscribedTimerRef.current = null;
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeyboardEvents);
		return () => window.removeEventListener("keydown", handleKeyboardEvents);
	}, [handleKeyboardEvents]);

	useEffect(() => {
		const unsubscribedTimerInterval = setInterval(() => {
			if (!unsubscribedTimerRef.current) return;
			if (unsubscribedTimerRef.current.remainingTime <= 0) {
				setAlert("TIME_LIMIT");
				setAlertedTrack(currentTrack?.id);
				pause();
			}
		}, 1000); // checking every second helps ensure a graceful stop for the generated audio

		// clean up the timer interval when the component unmounts
		return () => clearInterval(unsubscribedTimerInterval);
	});

	return (
		<AudioContext.Provider
			value={{
				alert,
				alertedTrack,
				audioRef,
				clearAlert,
				currentTrack,
				handleMuteEvent,
				isPlaying,
				isUserEngaged,
				pause,
				play,
				resetTimer: handleTimerReset,
				selectTrack,
				setVolume,
				setWhiteNoiseFilters,
				volume,
			}}
		>
			{children}
		</AudioContext.Provider>
	);
};

export default AudioProvider;
