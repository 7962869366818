import { ChevronRight, LogOut, User, LogIn } from "lucide-react";

import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from "@/components/ui/sidebar";
import { Link, useNavigate } from "react-router";
import { useAuth } from "@/providers/auth-provider";
import type { ClientUserModel } from "@coffitivity/shared-ts-types";
import { Button } from "@/components/ui/button";

const UnAuthenticatedMenu = () => (
	<Link to="/auth/log-in">
		<Button variant="secondary" size="lg" className="w-full">
			Login
			<LogIn />
		</Button>
	</Link>
);

const AuthenticatedMenu = ({
	user,
	logout,
}: { user: ClientUserModel; logout: () => void }) => {
	const { isMobile } = useSidebar();
	const navigate = useNavigate();

	const handleLogout = () => {
		logout();
		navigate("/");
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<SidebarMenuButton
					size="lg"
					className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
				>
					<Avatar className="h-8 w-8 rounded-lg">
						{/* <AvatarImage src={user.avatar} alt={user.name} /> */}
						<AvatarFallback className="rounded-lg">
							<User />
						</AvatarFallback>
					</Avatar>
					<div className="grid flex-1 text-left text-sm leading-tight">
						<span className="truncate text-xs">{user.email}</span>
					</div>
					<ChevronRight className="ml-auto size-4" />
				</SidebarMenuButton>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
				side={isMobile ? "bottom" : "right"}
				align="end"
				sideOffset={4}
			>
				<DropdownMenuGroup>
					<Link to="/account">
						<DropdownMenuItem>
							<User />
							Account
						</DropdownMenuItem>
					</Link>
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={handleLogout}>
					<LogOut />
					Log out
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export function NavUser() {
	const { isAuthenticated, logout, user } = useAuth();

	return (
		<SidebarMenu>
			<SidebarMenuItem>
				{isAuthenticated ? (
					<AuthenticatedMenu user={user} logout={logout} />
				) : (
					<UnAuthenticatedMenu />
				)}
			</SidebarMenuItem>
		</SidebarMenu>
	);
}
