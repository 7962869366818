import mixpanel from "mixpanel-browser";

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL;
const ENV = import.meta.env.VITE_ENV;

export interface UserAnalytics {
	identify: (userId: string) => void;
	trackPlaybackPaused: (trackId: string) => void;
	trackPlaybackStarted: (trackId: string) => void;
	trackPageView: (pathname: string) => void;
	trackPremiumAlertClicked: () => void;
	trackModalConverted: (modalName: string, trackId?: string) => void;
	tracKModalOpened: (modelName: string, trackId?: string) => void;
	trackLogIn: () => void;
	trackLogOut: () => void;
}

if (!MIXPANEL_TOKEN) {
	console.warn("Mixpanel token is missing! Check your .env file.");
}

// This might be better setup as a singleton
mixpanel.init(MIXPANEL_TOKEN, {
	debug: ENV !== "production",
});

// there's probably a better way to handle when to send events
export const userAnalytics: UserAnalytics = {
	identify: (userId: string) => {
		mixpanel.identify(userId);
	},
	trackPlaybackPaused: (trackId: string) => {
		mixpanel.track("playback_paused", {
			track_id: trackId,
		});
	},
	trackPlaybackStarted: (trackId: string) => {
		mixpanel.track("playback_started", {
			track_id: trackId,
		});
	},
	trackPageView: (pathname: string) => {
		mixpanel.track("page_view", {
			pathname,
		});
	},
	trackModalConverted: (modalName: string, trackId?: string) => {
		mixpanel.track("modal_converted", {
			model_name: modalName,
			track_name: trackId,
		});
	},
	tracKModalOpened: (modelName: string, trackId?: string) => {
		mixpanel.track("modal_opened", {
			model_name: modelName,
			track_name: trackId,
		});
	},
	trackPremiumAlertClicked: () => {
		mixpanel.track("premium_alert_clicked");
	},
	trackLogIn: () => {
		mixpanel.track("log_in");
	},
	trackLogOut: () => {
		mixpanel.track("log_out");
	},
};
