export class WhiteNoiseGenerator {
	private context: AudioContext;
	private whiteNoise: AudioBufferSourceNode;
	private filters: Record<string, BiquadFilterNode>;
	private gainNode: GainNode;
	private isPlaying = false;
	private targetVolume: number;

	constructor(
		initialFilters: Record<string, number> = {},
		initialVolume = 0.75,
	) {
		this.context = new (
			window.AudioContext ||
			(window as unknown as { webkitAudioContext: typeof AudioContext })
				.webkitAudioContext
		)();

		this.whiteNoise = this.createWhiteNoise();
		this.filters = this.createEqBands();
		this.gainNode = this.context.createGain();
		this.targetVolume = this.filterVolume(initialVolume);

		let previousNode: AudioNode = this.whiteNoise;
		for (const filter of Object.values(this.filters)) {
			previousNode.connect(filter);
			previousNode = filter;
		}

		this.setFilterGains(initialFilters);

		// Set initial gainNode volume (start at 0 for fade-in effect)
		this.gainNode.gain.setValueAtTime(0, this.context.currentTime);

		previousNode.connect(this.gainNode);
		this.gainNode.connect(this.context.destination);
	}

	private filterVolume(volume: number): number {
		return volume > 0 ? volume / 10 : volume;
	}

	private createWhiteNoise(): AudioBufferSourceNode {
		const bufferSize = 2 * this.context.sampleRate; // 2 seconds
		const noiseBuffer = this.context.createBuffer(
			1,
			bufferSize,
			this.context.sampleRate,
		);
		const output = noiseBuffer.getChannelData(0);

		for (let i = 0; i < bufferSize; i++) {
			output[i] = Math.random() * 2 - 1;
		}

		const whiteNoise = this.context.createBufferSource();
		whiteNoise.buffer = noiseBuffer;
		whiteNoise.loop = true;
		return whiteNoise;
	}

	private createEqBands(): Record<string, BiquadFilterNode> {
		const frequencies: Record<string, number> = {
			subBase: 20,
			lowBase: 60,
			base: 120,
			highBase: 250,
			lowMids: 500,
			mids: 1000,
			highMids: 2000,
			lowTreble: 4000,
			treble: 8000,
			highTreble: 16000,
		};

		const filters: Record<string, BiquadFilterNode> = {};

		for (const [band, freq] of Object.entries(frequencies)) {
			const filter = this.context.createBiquadFilter();
			filter.frequency.value = freq;
			const key = band.toLowerCase();

			if (key.includes("base")) {
				filter.type = "lowshelf";
			} else if (key.includes("treble")) {
				filter.type = "highshelf";
			} else {
				filter.type = "peaking";
				filter.Q.value = 1;
			}

			filter.gain.value = 0;
			filters[band] = filter;
		}

		return filters;
	}

	/** Start playback with fade-in */
	public start(): void {
		if (!this.isPlaying) {
			this.whiteNoise.start(0);
			this.isPlaying = true;
		}

		if (this.context.state === "suspended") {
			this.context.resume();
		}

		const fadeDuration = 1; // 1 second fade-in
		const currentTime = this.context.currentTime;

		this.gainNode.gain.cancelScheduledValues(currentTime);
		this.gainNode.gain.setValueAtTime(0, currentTime);
		this.gainNode.gain.linearRampToValueAtTime(
			this.targetVolume,
			currentTime + fadeDuration,
		);
	}

	/** Stop playback with fade-out */
	public stop(): void {
		if (this.context.state === "running") {
			const fadeDuration = 1; // 1 second fade-out
			const currentTime = this.context.currentTime;

			this.gainNode.gain.cancelScheduledValues(currentTime);
			this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, currentTime);
			this.gainNode.gain.linearRampToValueAtTime(0, currentTime + fadeDuration);

			setTimeout(() => {
				if (this.context.state === "running") {
					this.context.suspend();
				}
			}, fadeDuration * 1000);
		}
	}

	public setFilterGain(band: string, value: number): void {
		if (this.filters[band]) {
			this.filters[band].gain.value = value;
		} else {
			console.warn(`Filter band ${band} does not exist.`);
		}
	}

	public setFilterGains(values: Record<string, number>): void {
		for (const [band, value] of Object.entries(values)) {
			this.setFilterGain(band, value);
		}
	}

	public setVolume(volume: number): void {
		this.targetVolume = this.filterVolume(volume);
		const fadeDuration = 0.5; // 0.5 second smooth volume change
		const currentTime = this.context.currentTime;

		this.gainNode.gain.cancelScheduledValues(currentTime);
		this.gainNode.gain.linearRampToValueAtTime(
			this.targetVolume,
			currentTime + fadeDuration,
		);
	}
}
