import { useState } from "react";
import { UpgradeToPremiumModal } from "./upgrade-to-premium-modal";
import { useAudio } from "@/providers/audio-provider";
import { InterruptionModal } from "./interruption-modal";

const ModalController = () => {
	const [, setIsModalOpen] = useState(false);
	const { alert, clearAlert, resetTimer, alertedTrack } = useAudio();

	const closeModal = () => {
		setIsModalOpen(false);
		clearAlert();
	};

	const closeAndReset = () => {
		setIsModalOpen(false);
		clearAlert();
		resetTimer();
	};

	const ModalToRender = () => {
		switch (alert) {
			case "TIME_LIMIT":
				return (
					<InterruptionModal
						isOpen={!!alert}
						close={closeAndReset}
						alert={alert}
						trackId={alertedTrack}
					/>
				);
			case "PREMIUM_REQUIRED":
				return (
					<UpgradeToPremiumModal
						isOpen={!!alert}
						close={closeModal}
						alert={alert}
						trackId={alertedTrack}
					/>
				);
			default:
				break;
		}
	};

	return <ModalToRender />;
};

export default ModalController;
