import { Outlet, useLocation } from "react-router";
import { useEffect, useRef } from "react";
import { useIsMobile } from "@/hooks/use-mobile";
import { MobileNavbarTop } from "@/components/mobile/mobile-navbar-top";
import MobileNavbarBottom from "@/components/mobile/mobile-navbar-bottom";
import { AudioPlayer } from "@/components/audio-player";
import { useAudio } from "@/providers/audio-provider";
import { AppSidebar } from "@/components/app-sidebar";
import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import GetPremiumAlert from "@/components/get-premium-alert";
import { cn } from "@/lib/utils";
import ModalController from "@/components/modals/modal-controller";

const Dashboard = () => {
	const isMobile = useIsMobile();
	const { currentTrack } = useAudio();
	const location = useLocation();
	const contentRef = useRef<HTMLDivElement | null>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: not sure why it doesn't like pathname
	useEffect(() => {
		if (contentRef.current) {
			contentRef.current.scrollTop = 0;
		}
	}, [location.pathname]);

	return (
		<div className="relative">
			{isMobile ? (
				<>
					<MobileNavbarTop />
					<div className="px-4 sm:px-8">
						<div
							ref={contentRef}
							className={cn(
								"bg-background rounded-lg p-4 sm:p-8 overflow-y-scroll",
								currentTrack
									? "h-[calc(100svh-14rem)]"
									: "h-[calc(100svh-9rem)]",
							)}
						>
							<GetPremiumAlert />
							<Outlet />
						</div>
					</div>
					<AudioPlayer />
					<MobileNavbarBottom />
				</>
			) : (
				<SidebarProvider>
					<AppSidebar />
					<SidebarInset>
						<div
							ref={contentRef}
							className={cn(
								`
                mt-4 mr-4 px-4 md:p-12 xl:p-16
                bg-background rounded-lg overflow-y-scroll border shadow
                `,
								currentTrack
									? "h-[calc(100svh-8rem)]"
									: "h-[calc(100svh-2.25rem)]",
							)}
						>
							<GetPremiumAlert />
							<Outlet />
						</div>
						<AudioPlayer />
					</SidebarInset>
				</SidebarProvider>
			)}
			<ModalController />
		</div>
	);
};

export default Dashboard;
