import * as Sentry from "@sentry/react";
import type { ApiRequestResponse } from "@coffitivity/shared-ts-types";

const auth = {
	forgotPassword: async (
		email: string,
	): Promise<ApiRequestResponse<object>> => {
		const payload = {
			email,
		};

		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/auth/forgot-password`,
				{
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
					body: JSON.stringify(payload),
				},
			);

			const json: { data: object; message: string } = await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
	refresh: async (): Promise<ApiRequestResponse<{ accessToken: string }>> => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/auth/refresh`,
				{
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
					credentials: "include",
				},
			);

			const json: { data: { accessToken: string }; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
	resetPassword: async (
		password: string,
		token: string,
	): Promise<ApiRequestResponse<object>> => {
		try {
			const payload = {
				password,
				token,
			};

			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/auth/reset-password`,
				{
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
					body: JSON.stringify(payload),
				},
			);

			const json: { data: object; message: string } = await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (error) {
			console.error(`Error: ${error}`);
			Sentry.captureException(error);
			return [null, error as Error];
		}
	},
	signIn: async (
		email: string,
		password: string,
	): Promise<ApiRequestResponse<{ accessToken: string }>> => {
		const payload = {
			email,
			password,
		};

		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/auth/sign-in`,
				{
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
					credentials: "include", // Important to include cookies
					body: JSON.stringify(payload),
				},
			);

			if (response.status === 500) {
				return [
					null,
					new Error(
						"We apologize, but an error occurred. Please try again later.",
					),
				];
			}

			const json: { data: { accessToken: string }; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
	signUp: async (
		email: string,
		password: string,
		newsletter?: boolean,
	): Promise<ApiRequestResponse<object>> => {
		const payload = {
			email,
			password,
			newsletter,
		};

		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/auth/sign-up`,
				{
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
					body: JSON.stringify(payload),
				},
			);

			const json: { data: object; message: string } = await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
	verifyToken: async (
		token: string,
	): Promise<ApiRequestResponse<{ accessToken: string }>> => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/auth/verify?token=${token}`,
				{
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
					credentials: "include", // Important to include cookies
				},
			);

			const json: { data: { accessToken: string }; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
};

export default auth;
